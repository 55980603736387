import React from "react";
import DetailTable from "../component/DetailTable";
import Nav from "../component/Nav";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Pdf from "../Uifiles/lipapap.pdf";
import SignleProjectDiscription from "../component/SignleProjectDiscription"

export default function DataEkamuthuwa() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />

        <div className="col-10 offset-1">
          <div className="row">
            
            <SignleProjectDiscription
            type = "Mobile application"
            name = "Unknown - Wifi Application"
            dis1= "The WiFi Device Management App is a mobile application developed using React Native that enables users to manage and control their WiFi devices from their smartphones. The app was developed for both iOS and Android operating systems and provides a simple and intuitive interface for users to manage their devices."
            dis2= ""
            youtube = "https://www.youtube.com/embed/IH4DYGJnJiw"
            />

            

      
            <DetailTable
              link=""
              doc=""
              ui={Pdf}
              special="Can not publish Application name"
            />
          </div>
        </div>

        <Contact />
        <Footer />
      </div>
    </div>
  );
}
