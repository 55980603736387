import React from "react";
import "../styles/Home.css";
import BlueService from "../services/BlueService";
import RedService from "../services/RedService";
import DarkService from "../services/DarkServices";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/solid";
import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import { PhotoIcon } from "@heroicons/react/24/solid";
import "../styles/Home.css";

export default function Services() {
  return (
    <div className="col-10 offset-1 mt-5">
      <div className="row">
        <div className=" col-12 col-lg-7  text-start aboutText">
          <h2
            className="text-apitalize fw-bold text-1xl lg:text-2xl "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="0"
            data-aos-once="true"
          >
            SERVICES
          </h2>

          <h6
            className="text-uppercase fs-1 fw-bold text-light "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
          >
            our services
          </h6>

          <p
            className="homePara text-base text-sm lg:text-base"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-once="true"
          >
            we offer a range of services that cater to all your digital needs.
            From web development to mobile application development, we've got
            you covered.
          </p>
        </div>

        <BlueService
          title="Web Development"
          discription="Whether you need a simple business website, a portfolio website, a Learning Management System (LMS), or an e-commerce web application, we've got you covered. "
          link="/works"
        >
          <ComputerDesktopIcon className="text-white w-13 lg:w-20" />
        </BlueService>

        <DarkService
          title="Mobile App Development"
          discription=" Our team of skilled developers use the latest technology and coding practices to create custom mobile applications that are tailored to your business needs. From Android to iOS and hybrid app development, we have the expertise to deliver high-quality results that exceed your expectations."
          link="/works"
        >
          <DevicePhoneMobileIcon className="text-white w-13 lg:w-20" />
        </DarkService>

        <DarkService
          title="UI/UX Design"
          discription=" We believe that a great user interface and user experience are essential to the success of any digital project, which is why we work closely with you to understand your business goals and target audience."
          link="/works"
        >
          <PuzzlePieceIcon className="text-white w-13 lg:w-20" />
        </DarkService>

        <RedService
          title="Graphics Design"
          discription="  Our team of skilled designers use the latest design software and techniques to create custom graphics that are tailored to your business needs. From logos and business cards to brochures and social media graphics, we're committed to providing you with exceptional service and results."
          link="/works"
        >
          <PhotoIcon className="text-white w-13 lg:w-20" />
        </RedService>

        
      </div>
    </div>
  );
}
