import React from "react";
import Img from "../resources/graphics_1.jpg";
import Img2 from "../resources/graphics_2.jpg";
import Img3 from "../resources/graphics_3.jpg";
import Img4 from "../resources/graphics_4.jpg";
import GraphicsItem from "./GraphicsItem";

export default function DesignProject() {
  return (
    <div className="col-10 offset-1 text-white" id="GRAPHICS">
      <div className="row">
        <div className=" col-12 col-lg-9  text-start aboutText mt-5 mb-5">
          <h6 className="text-uppercase fs-1 fw-bold text-info ">
            Graphics DeSigning
          </h6>
          <p className="homePara text-base text-sm lg:text-base">
            Our team of experienced graphic designers works collaboratively with
            clients to create custom designs that align with their brand
            identity and marketing objectives.
          </p>
        </div>
        <div className="col-12">
          <div className="row">
        <GraphicsItem img={Img} dis="Logo Design" />

        <GraphicsItem img={Img2} dis="Logo Design" />

        <GraphicsItem img={Img3} dis="Logo Design" />

        <GraphicsItem img={Img4} dis="Brand Design" />
      </div>
    </div>

    </div>
    </div>
  );
}
