import React from "react";
import Img from "../resources/hapchatUI.png";
import Img2 from "../resources/taprodev.png";
import Img3 from "../resources/calUi.png";
import Img4 from "../resources/dataEkamuthuwa.jpg";
import Img5 from "../resources/techlanka.png";
import Img6 from "../resources/bookMate.png";
import Img7 from "../resources/kavinduMotors.png";
import UiItem from "./UiItem";
import Pdf_1 from "../Uifiles/HapChat_Ui.pdf";
import Pdf_2 from "../Uifiles/taproDev.pdf";
import Pdf_3 from "../Uifiles/calUi.pdf";
import Pdf_4 from "../Uifiles/WifiApplication.pdf";
import Pdf_5 from "../Uifiles/TechlankaUI.pdf";
import Pdf_6 from "../Uifiles/BookMateUI.pdf";
import Pdf_7 from "../Uifiles/kavinduMotors.pdf";

export default function UiProjects() {
  return (
    <div className="col-10 offset-1 text-white" id="UI">
      <div className="row">
        <div className=" col-12 col-lg-9  text-start aboutText mt-5 mb-5">
          <h6 className="text-uppercase fs-1 fw-bold text-info ">UI/UX DeSigning</h6>
          <p className="homePara text-base text-sm lg:text-base">
            Our UI/UX design team combines creativity and technical expertise to
            craft interfaces that are not only visually stunning but also
            intuitive and user-friendly. We work closely with our clients to
            understand their business goals and target audience, allowing us to
            create custom designs that align with their brand identity and
            values.
          </p>
        </div>

        <div className="col-12">
          <div className="row">
            <UiItem
              to={Pdf_1}
              img={Img}
              title="HapChat"
              dis="Ui design for mobile chat application."
            />

            <UiItem
              to={Pdf_2}
              img={Img2}
              title="Tapro Dev"
              dis="Ui design for web portpolio."
            />

            <UiItem
              to={Pdf_4}
              img={Img4}
              title="WIFI App"
              dis="Ui design for wifi mobile application."
            />

            <UiItem
              to={Pdf_5}
              img={Img5}
              title="Tech Lanka"
              dis="Ui design for Buying and Selling web."
            />

<UiItem
              to={Pdf_6}
              img={Img6}
              title="Book Mate"
              dis="Ui design for Online library system."
            />

<UiItem
              to={Pdf_7}
              img={Img7}
              title="Kavindu Motors"
              dis="Ui design for Online store."
            />

            <UiItem
              to={Pdf_3}
              img={Img3}
              title="Calculator"
              dis="Ui design for desktop calculator."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
