import React from 'react'

export default function SignleProjectDiscription(props) {
  return (
    <div>
        <div className=" col-12 col-lg-9  text-start aboutText mt-5 mb-5">
              <h2
                className="text-apitalize fw-bold text-1xl lg:text-2xl "
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-once="true"
              >
               {props.type}
              </h2>
              <br />
              <h6
                className="text-uppercase fs-1 fw-bold text-white"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="100"
                data-aos-once="true"
              >
                {props.name}
              </h6>
              <br />
              <span
                className="homePara text-base text-sm lg:text-base"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="200"
                data-aos-once="true"
              >
                {props.dis1}
              </span>
              <br />
              <br />
              <span
                className="homePara text-base text-sm lg:text-base"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="200"
                data-aos-once="true"
              >
                {props.dis2}
              </span>
            </div>

<div className="col-lg-8 col-12 offset-0 offset-lg-2">
<div className="row">
  <div class="ratio ratio-16x9">
    <iframe
      src={props.youtube}
      title="YouTube video"
      allowfullscreen
    ></iframe>
  </div>
</div>
</div>

    </div>
  )
}
