import React from "react";
import "../styles/Home.css";
import { Link } from "react-router-dom";

export default function BlueWork(props) {
  

  return (
    <div className="col-12 col-lg-6  blueWork-bg p-lg-5 p-3 pt-4 pb-4 "
    data-aos="fade-up"
    data-aos-duration="500"
    data-aos-delay="300"
    data-aos-once = "true">
      <div className="row ">
        <p className="workTitle fw-bold text-sm lg:text-lg">{props.title}</p>
        <p className="fw-bold text-3xl lg:text-6xl">{props.name}</p>
        <p className="Home-main-para mt-2">
          {props.discription}
        </p>

        <Link to={props.link}>
          <button className="round-btn mt-3  ">View Project</button>
        </Link>
      </div>
    </div>
  );
}
