import React, { useEffect, useState } from "react";
import "../styles/Home.css";

export default function HomeTaskBar() {

  const [num, setNum] = useState(0);

useEffect(() => {
  const intervalId = setInterval(() => {
    setNum((prevNum) => {
      if (prevNum === 99) {
        clearInterval(intervalId);
        return prevNum;
      }
      return prevNum + 1;
    });
  }, 30);
  return () => {
    clearInterval(intervalId);
  };
}, []);
  return (
    <div className="col-12 bg-info p-3 taskBar ">
      <div className="row">
        <div className="col-10 offset-1">
          <div className="row g-2">

            <div className="col-12   col-lg-4 text-start d-flex justify-content-center">
              <div className="row align-items-center">

                <div className=" col-4">
                  <span className="text-white fw-bold text-4xl" id="text1">{num}%</span>
                </div>

                <div className="col-8 text-start text-white fw-bold">
                 
                    Success in getting customer
                 
                </div>

              </div>
            </div>

            <div className="col-12   col-lg-4 text-start d-flex justify-content-center">
              <div className="row align-items-center">

                <div className=" col-lg-3 col-4">
                <span className="text-white fw-bold text-4xl" id="text3">{num}%</span>
                </div>

                <div className="col-8 text-start text-white fw-bold">
                 
                Reviews are very satisfied with us
                 
                </div>

              </div>
            </div>

            <div className="col-12   col-lg-4 text-start d-flex justify-content-center">
              <div className="row align-items-center">

                <div className=" col-4">
                  <span className="text-white fw-bold text-4xl" id="text3">{num}%</span>
                </div>

                <div className="col-8 text-start text-white fw-bold">
                 
                    Success in getting customer
                 
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );

  
}

