import React from "react";
import "../styles/about.css";
import "../styles/Home.css";
import AboutImg from "../component/aboutImg";

const About = () => {
  return (
    <div id="AboutID" className="about col-10 pt-5 offset-1 text-light">
      <div className="row">
        <div className="col-12 col-lg-6 ">
          <AboutImg />
        </div>

        <div className=" col-12 mt-5 mt-lg-0 col-lg-6  text-start aboutText ">
          <h2
            className="text-apitalize fw-bold text-1xl lg:text-2xl "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="0"
            data-aos-once="true"
          >
            ABOUT US
          </h2>
          <br />
          <h6 className="text-uppercase fs-1 fs-1 fw-bold text-white"
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="100"
          data-aos-once="true">
            We’re On Mission To Help Business Grow Faster Thanever.
          </h6>
          <br />
          <p className="homePara text-base text-sm lg:text-base"
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="200"
          data-aos-once="true">
            Our team of experts is dedicated to providing customized web design
            and development solutions that are tailored to your business needs.
            From creating a stunning online presence to developing powerful
            e-commerce platforms and web applications. Let's work together and
            achieve greatness!
          </p>
          <br />

          <button className="round-btn" type=""
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-delay="300"
          data-aos-once="true">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
