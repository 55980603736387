
import './App.css';
import Home from './pages/Home';
import Works from './pages/works';
import ContactUs from './pages/ContactUs';
import OurServices from './pages/Services';
import HapChat from './pages/HapChat';
import TechLanka from './pages/TechLanka';
import KavinduMotors from './pages/KavinduMotors';
import Lipapap from './pages/Lipapap';
import DataEkamuthuwa from './pages/DataEkamuthuwa';
import Edubook from './pages/Edubook';
import Taprodev from './pages/Taprodev';
import {
 BrowserRouter, Routes, Route, 
} from "react-router-dom";


function App() {

  
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/works" element={<Works/>} />
      <Route path="/contact" element={<ContactUs/>} />
      <Route path="/services" element={<OurServices/>} />
      <Route path="/hapchat" element={<HapChat/>} />
      <Route path="/techlanka" element={<TechLanka/>} />
      <Route path="/kavindumotors" element={<KavinduMotors/>} />
      <Route path="/lipapap" element={<Lipapap/>} />
      <Route path="/wifiApp" element={<DataEkamuthuwa/>} />
      <Route path="/edubook" element={<Edubook/>} />
      <Route path="/taprodev" element={<Taprodev/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
