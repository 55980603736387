import React from 'react'
import Table from 'react-bootstrap/Table';

export default function DetailTable(props) {
  return (
    <Table striped bordered hover variant="dark" className='mt-5'>
      
      <tbody>
        <tr>
          <td className='col-lg-3 col-5'>Project Link</td>
          <td className='col-lg-9 col-7'>
            <a href={props.link} without rel="noopener noreferrer" target="_blank">{props.link}</a>
          </td>
        </tr>

        <tr>
          <td className='col-lg-3 col-5'>Documentry</td>
          <td className='col-lg-9 col-7'>
            <a href={props.doc} without rel="noopener noreferrer" target="_blank" className=' btn btn-sm col-12 col-lg-2 btn-primary'>Click Here</a>
          </td>
        </tr>

        <tr>
          <td className='col-lg-3 col-5'>UI Design</td>
          <td className='col-lg-9 col-7'>
           <a href={props.ui} without rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-primary col-12 col-lg-2" >View</a>
          </td>
        </tr>

      

        <tr>
          <td className='col-lg-3 col-5'>Special</td>
          <td className='col-lg-9 col-7 text-danger'>
            {props.special}
          </td>
        </tr>
        
      </tbody>
    </Table>
  )
}
