import React from "react";
import DetailTable from "../component/DetailTable";
import Nav from "../component/Nav";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Pdf from "../Uifiles/TechlankaUI.pdf";
import Doc from "../documents/techlanka.pdf";
import SignleProjectDiscription from "../component/SignleProjectDiscription"

export default function TechLanka() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />

        <div className="col-10 offset-1">
          <div className="row">
            
            <SignleProjectDiscription
            type = "WEB APPLICATION"
            name = "Tech lanka"
            dis1= "Tech Lanka is a free e-commerce platform that specializes in electronic items buying and selling. It offers a wide range of electronic products, including mobile phones, laptops, cameras, gaming consoles, audio systems, and many more. The platform is designed to be user-friendly and easy to navigate, allowing users to browse through different products and sellers easily."
            dis2= ""
            youtube = "https://www.youtube.com/embed/ZbApAwAWImk?rel=0"
            />

            

      
            <DetailTable
              link=""
              doc={Doc}
              ui={Pdf}
              special="No Special note"
            />
          </div>
        </div>

        <Contact />
        <Footer />
      </div>
    </div>
  );
}
