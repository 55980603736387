import React from 'react'
import Img from "../resources/technologies.png"
import Img2 from "../resources/technologies_2.png"

export default function Technologies() {
  return (
    <div className="col-12">
        <div className="row">
        <div className="col-12 text-center mt-5 aboutText">
        <h6
                    className="text-apitalize fs-1 fw-bold text-1xl lg:text-2xl "
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0"
                    data-aos-once="true"
                  >
                    TECHNOLOGIES WE USED
                  </h6>
        </div>
   <div className="col-12 mt-2 p-1 text-center technology">
    <div className="row">
        <div className="col-8 offset-2 d-none d-lg-block">
        <img src={Img} alt="technologies" className='border-0 img-thumbnail bg-transparent' />
        </div>
        <div className="col-10 offset-1 d-lg-none d-block">
        <img src={Img2} alt="technologies" className='border-0 img-thumbnail bg-transparent' />
        </div>
    </div>
   </div>
        </div>
    </div>
  )
}
