import React from 'react'
import Img from "../resources/hapChat.jpg";
import Img2 from "../resources/dataEkamuthuwa.jpg";
import WorksImgone from "../works/WorksImgOne";
import WorksImgTwo from "../works/WorksImgTwo";
import BlueWork from "../works/BlueWork";
import WhiteWork from "../works/WhiteWork";

export default function MobileProject() {
  return (
    <div className="col-10 offset-1 text-white" id="MOBILE">
      <div className="row">
        <div className=" col-12 col-lg-9  text-start aboutText mt-5 mb-5">
          <h6 className="text-uppercase fw-bold fs-1 text-info ">Mobile applications</h6>
          <p className="homePara text-base text-sm lg:text-base">
          Our team of skilled developers use the latest technology and coding practices to create custom mobile applications that are tailored to your business needs. From Android to iOS and hybrid app development, we have the expertise to deliver high-quality results that exceed your expectations.
          </p>
        </div>

        <WorksImgone img={Img} />
        <BlueWork
          title="CHAT APPLICATION"
          name="Hap Chat"
          discription="Hap chat is chating application like WhatsApp, Telegram , Viber etc."
          link="/hapchat"
        />

        <WhiteWork
        title="WIFI APPLICATION"
        name="Unknown"
        discription="This is a wifi device manager application for IOS and Andoid devices."
        link="/wifiApp"
        />
    <WorksImgTwo img={Img2} />

    
    
      </div>
    </div>
  )
}
