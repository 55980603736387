import React from 'react'

export default function GraphicsItem(props) {
  return (
    
            <div
              className="col-12 col-lg-4 p-4 pt-3 "
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <div className="row">
                <div
                  className="col-12 bg-white"
                  style={{
                    backgroundImage: `url(${props.img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "220px",
                  }}
                ></div>
                <div className="col-12 blueWork-bg p-3">
                  <span
                    className="text-white-50"
                    
                  >
                   {props.dis}
                  </span>
                </div>
              </div>
            </div>
          
  )
}
