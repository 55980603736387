import React from "react";
import DetailTable from "../component/DetailTable";
import Nav from "../component/Nav";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Pdf from "../Uifiles/kavinduMotors.pdf";
import SignleProjectDiscription from "../component/SignleProjectDiscription"

export default function KavinduMotors() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />

        <div className="col-10 offset-1">
          <div className="row">
            
            <SignleProjectDiscription
            type = "WEB APPLICATION"
            name = "Kavindu Motors"
            dis1= "The Kavindu Motors E-commerce website is a platform where customers can browse and purchase a wide range of vehicle modification parts such as exhaust systems, turbochargers, suspension kits, and more. The website features a user-friendly interface that allows customers to easily find the products they need and complete their purchase with just a few clicks."
            dis2= "To develop the website, I used a combination of HTML, CSS, JavaScript, and PHP. I also integrated a secure payment gateway to ensure that customer transactions are safe and secure. Additionally, I implemented a content management system (CMS) that allows Kavindu Motors to easily manage their product listings, prices, and inventory."
            youtube = "https://www.youtube.com/embed/MDPPmtsOk18"
            />

            

      
            <DetailTable
              link="https://www.kavindumotors.com"
              doc=""
              ui={Pdf}
              special="No Special note"
            />
          </div>
        </div>

        <Contact />
        <Footer />
      </div>
    </div>
  );
}
