import React from "react";
import FeedbackItem from "./feedbackItem";
import Img from "../resources/person.jpg";

export default function Testimonials() {
  return (
    <div className=" col-12 testimonial-bg mt-5">
      <div className="row">
        <div className="dark-bg col-12 pt-5 pb-5">
          <div className="row">
            <div className="col-10 offset-1">
              <div className="row">
                <div className=" col-12 col-lg-7  text-start aboutText">
                  <h2
                    className="text-apitalize fw-bold text-1xl lg:text-2xl "
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-delay="0"
                    data-aos-once="true"
                  >
                    TESTIMONIALS
                  </h2>
                  <br />
                  <h6
                    className="text-uppercase fs-1 text-white fw-bold "
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    Customer Testimonials
                  </h6>

                  <p
                    className="homePara text-base text-sm lg:text-base"
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-delay="200"
                    data-aos-once="true"
                  >
                    Here at TaproDev, we're proud to have provided top-quality products and services to our valued customers. But don't just take our word for it! Our satisfied customers have shared their experiences with us, and we're thrilled to showcase their feedback on our testimonial page
                  </p>
                </div>

                <div className="col-12">
                  <div className="row">
                    <FeedbackItem
                      title="Good customer service."
                      stars="5"
                      feedback="Your team demonstrated a deep understanding of Kavindu Motors' requirements and was able to deliver a website that exceeded our expectations. The design is aesthetically pleasing, user-friendly, and responsive across all devices, making it easy for our customers to navigate and purchase products. TaproDev Highly recommended"
                      img={Img}
                      name="Kavindu Dissanayake"
                      special="OWNER - Kavindu Motors"
                    />
                    <div className="col-1"></div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
