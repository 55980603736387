import React from "react";
import DetailTable from "../component/DetailTable";
import Nav from "../component/Nav";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Pdf from "../Uifiles/HapChat_Ui.pdf";
import Doc from "../documents/hapchat.pdf";
import SignleProjectDiscription from "../component/SignleProjectDiscription"

export default function HapChat() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />

        <div className="col-10 offset-1">
          <div className="row">
            
            <SignleProjectDiscription
            type = "MOBILE APPLICATION"
            name = "HapChat"
            dis1= "Hap Chat is a state-of-the-art chat application that allows you to send messages and images to your contacts with ease With its simple and intuitive interface you can quickly connect with your friends and start chatting right away."
            dis2= "Although Hap Chat doesn't support voice or video calls, it more than makes up for it with its extensive messaging features. You can send text messages, photos, and even create group chats to stay in touch with all your friends at once. In addition, you can send friend requests to people you want to connect with and build your network of contacts."
            youtube = "https://www.youtube.com/embed/kVyPFfeZu1k"
            />

            

      
            <DetailTable
              link=""
              doc={Doc}
              ui={Pdf}
              special="No Special note"
            />
          </div>
        </div>

        <Contact />
        <Footer />
      </div>
    </div>
  );
}
