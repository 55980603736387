import React from "react";
import { FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp, IoLogoGithub } from "react-icons/io";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="col-12 mt-5 ">
      <div className="row">
        <div className="col-12 col-lg-5 offset-lg-1 offset-0 p-3 pt-5">
          <div className="row">
            <div className=" col-12 text-start aboutText">
              <h2 className="text-apitalize fw-bold text-1xl lg:text-2xl  ">
                Contact
              </h2>

              <h6
                className="text-uppercase fs-1 fw-bold text-white"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="50"
                data-aos-once="true"
              >
                Let’s Scale Your Brand, Together
              </h6>
              <p
                className="homePara text-base text-sm lg:text-base"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="300"
                data-aos-once="true"
              >
                Our team is always happy to assist you and provide you with the
                information you need. We look forward to hearing from you soon!
              </p>

              <br />
              <p className="text-white fw-bold">Contact Us</p>
              <p className="homePara text-base  lg:text-base">
                No:254, Rjawella 02, Digana,Kandy <br />
                Sri Lanka.
              </p>
              <span className="text-white fw-bold">
                taprodev@gmail.com
              </span>
              <br />
              <br />
              <span className="text-white fw-bold">
                (+94) 75 733 3502 - Tharindu
              </span>
              <br />
              <span className="text-white fw-bold">
                (+94) 77 161 7400 - Sahan
              </span>
            </div>
            <div className="col-12 col-lg-6 homePara fs-3 mt-4">
              <div className="row gap-2 gap-lg-2">
                <div
                  className="col-1 col-lg-2"
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  <Link className="social" to={"https://web.facebook.com/profile.php?id=100092424012955"}>
                    <FaFacebook />
                  </Link>
                </div>
                <div
                  className="col-1 col-lg-2"
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-delay="200"
                  data-aos-once="true"
                  
                >
                  <Link className="social" to={"https://api.whatsapp.com/send?phone=94783814075&text="}>
                    <IoLogoWhatsapp />
                  </Link>
                </div>
                <div
                  className="col-1 col-lg-2"
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  <Link className="social" to={"https://www.linkedin.com/in/taprodev-solutions-643b5a275/"}>
                    <IoLogoGithub />
                  </Link>
                </div>
                <div
                  className="col-1 col-lg-2"
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  <Link className="social" to={"https://www.youtube.com/@taproDev"}>
                    <FaYoutube />
                  </Link>
                </div>
                <div
                  className="col-1 col-lg-2"
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  <Link className="social" to={"https://www.linkedin.com/in/taprodev-solutions-643b5a275/"}>
                    <FaLinkedin />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 blueWork-bg p-4 pt-5">
          <div className="row g-3">
            <div className="col-12 text-white fw-bold fs-4">Contact Us</div>
            <div className="col-lg-10 col-12">
              <p className="homePara text-base text-sm lg:text-base">
                We'd love to hear from you! If you have any questions or
                inquiries regarding our services, please don't hesitate to reach
                out to us.
              </p>
            </div>
            <div
              className="col-12 col-lg-10"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <Form.Group className="">
                <Form.Control
                  placeholder="Your Name"
                  className=" border-0 rounded-0 p-2 ps-4 mt-2"
                />
              </Form.Group>
            </div>

            <div
              className="col-12 col-lg-10"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <Form.Group className="">
                <Form.Control
                  placeholder="Email"
                  className="border-0 rounded-0 ps-4 p-2 mt-2"
                />
              </Form.Group>
            </div>

            <div
              className="col-12 col-lg-10"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <textarea
                cols="30"
                rows="5"
                placeholder="Message"
                className="border-0 form-control rounded-0 ps-4 p-2 mt-2"
              ></textarea>
            </div>

            <div className="col-12 mt-4">
              <button className="red-Round-btn">Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
