import React, { useEffect } from "react";
import Nav from "../component/Nav";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../component/Footer";
import Contact from "../component/Contact";
import Services from "../component/services";
import "../styles/Home.css";
import { Helmet } from "react-helmet";

export default function OurServices() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="container-fluid main-bg">
      <div className="row">


      <Helmet>
          <meta
            name="description"
            content="we offer a range of services that cater to all your digital needs. From web development to mobile application development, we've got you covered."
          />
          <meta
            name="keywords"
            content="
            taprodev,
            taprodev solutions,
            taprodev services,
            contact taprodev,
            web development,
            mobile application development,
            mobile application,
            graphics designing,
            logo design,
            web development company in sri lanka,
            sri lanka,
            kandy,
            graphic,
            a logo,
            app development,
            mobile app development,
            business logo,
            brand logo,
            business logo design,
            web dev"
          />
          <meta name="author" content="Taprodev" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Taprodev" />
          <meta
            property="og:description"
            content="we offer a range of services that cater to all your digital needs. From web development to mobile application development, we've got you covered."
          />
          <meta
            property="og:image"
            content="https://taprodev.com/favicon.ico"
          />
          <meta property="og:url" content="https://taprodev.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Taprodev" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Taprodev" />
          <meta
            name="twitter:description"
            content="we offer a range of services that cater to all your digital needs. From web development to mobile application development, we've got you covered."
          />
          <meta
            name="twitter:image"
            content="https://taprodev.com/favicon.ico"
          />
          <title>Taprodev Services</title>
        </Helmet>



        <div className="col-12 services-bg">
          <div className="row ">
            
            <div className="col-12  text-center dark-bg">
            <Nav 
            active = "Services"/>
              <div
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-white text-center fw-bold text-5xl lg:text-7xl "
              >
               Our Services
              </div>
              <p className="Home-main-para mt-3 col-10 offset-1 col-lg-6 offset-lg-3 ">
                we offer a range of services that cater to all your digital
                needs. From web development to mobile application development,
                we've got you covered.
              </p>
              <Link to={"/contact"}>
                <button className="round-btn mt-3 mb-4 mt-lg-5 ">
                  Contact us
                </button>
              </Link>
            </div>
          </div>
        </div>
       
       <Services/>
      
        <Contact/>

        <Footer/>
      </div>
    </div>
  );
}

