import React from "react";
import Img from "../resources/joinUs-1.jpeg";
import Img2 from "../resources/joinUs-2.jpg";

export default function JoinUs() {
  return (
    <div className="col-11 mt-5">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-6 ">
              <img
                src={Img}
                alt="img"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-once="true"
              />
            </div>
            <div className="col-6 mt-5 ">
              <img
                src={Img2}
                alt="img"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-once="true"

              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className=" col-12 mt-lg-5 mt-0 p-4 text-start aboutText">
              <h2
                className="text-apitalize fw-bold text-1xl lg:text-2xl "
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-once="true"

              >
                JOIN US
              </h2>
              <br />
              <h6
                className="text-uppercase fs-1 text-white fw-bold "
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="100"
                data-aos-once="true"

              >
                Several Things Define Us As a Company
              </h6>

              <p
                className="homePara text-base text-sm lg:text-base"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="200"
                data-aos-once="true"

              >
                Join our company and become part of a dynamic team that is
                committed to excellence and innovation. We are passionate about
                delivering exceptional products and services to our clients and
                creating a positive impact in our community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
