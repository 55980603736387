import React from 'react'
import Nav from '../component/Nav';
import Contact from '../component/Contact';
import Footer from '../component/Footer';
import "../styles/Home.css";

export default function ContactUs() {
  return (
    <div className="container-fluid main-bg">
      <div className="row">
        <div className="col-12 contact-bg">
          <div className="row ">
           
            <div className="col-12  text-center dark-bg">
            <Nav active = "Contact"/>
              <div
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-white text-center mt-5 fw-bold text-5xl lg:text-7xl "
              >
                Contact Us
              </div>
              <p className="Home-main-para mt-3 col-10 offset-1 col-lg-6 offset-lg-3 ">
              We'd love to hear from you! If you have any questions or inquiries regarding our services, please don't hesitate to reach out to us. 
              </p>
              
            </div>
          </div>
        </div>
        
        <Contact/>

        <Footer/>
      </div>
    </div>
  )
}
