import React from "react";

export default function UiItem(props) {
  return (
    
    <div className="col-12 col-lg-4 p-4 pt-3" data-aos="fade-up"
    data-aos-duration="500"
    data-aos-delay="0"
    data-aos-once="true">

      
      <div className="row">
      <a href={props.to} without rel="noopener noreferrer" target="_blank" className="col-12 p-0" >
        <div
          className="col-12 bg-white"
          style={{
            backgroundImage: `url(${props.img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "220px",
          }}
        ></div>
         </a>
        <div className="col-12 blueWork-bg p-3">
          <span
            className="text-white fw-bold h4"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="0"
            data-aos-once="true"
          >
            {props.title}
          </span>
          <br />
          <span
            className="text-white-50"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
          >
            {props.dis}
          </span>
        </div>
      </div>
    </div>
   
  );
}
