import React from "react";
import "../styles/about.css";
import "../styles/Home.css";
import WorksImgone from "../works/WorksImgOne";
import WorksImgTwo from "../works/WorksImgTwo";
import BlueWork from "../works/BlueWork";
import WhiteWork from "../works/WhiteWork";
import Img from "../resources/kavinduMotors.png";
import Img2 from "../resources/techLanka.jpg";
import Img3 from "../resources/hapChat.jpg";
import { Link } from "react-router-dom";

export default function HomeOurWorks() {
  return (
    <div className="col-10 offset-1 mt-5 text-light">
      <div className="row">
        <div className=" col-12 col-lg-7  text-start aboutText">
          <h2
            className="text-apitalize fw-bold text-1xl lg:text-2xl "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="0"
          >
            WORKS
          </h2>

          <h6
            className="text-uppercase fs-1 fw-bold "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
          >
            Our Works
          </h6>
          <p
            className="homePara text-base text-sm lg:text-base"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-once="true"
          >
            we offer a range of services that cater to all your digital needs.
            From web development to mobile application development, we've got
            you covered.
          </p>
        </div>
        <div className="col-12 col-lg-2 offset-lg-3 mt-2 mb-3">
          <Link to={"/works"}>
          <button className="red-Round-btn">See Our Works</button>
          </Link>
        </div>

        <WorksImgone img={Img} />

        <BlueWork
         title="WEB STORE "
         name="Kavindu Motors"
         discription="This is a web site for car modification item selling store. Customers can send thiere orders to store using this application  "
         link="/kavindumotors"
        />

        <WhiteWork
          title="WEB STORE - E-COMMERCE WEB"
          name="TECH LANKA"
          discription="Tech Lanka is a free e-commerce platform that specializes in electronic items buying and selling. It offers a wide range of electronic products, including mobile phones, laptops, cameras, gaming consoles, audio systems, and many more."
          link="/techlanka"
        />
        <WorksImgTwo img={Img2} />

        <WorksImgone img={Img3} />

        <BlueWork
          title="CHAT APP - MOBILE APPLICATION"
          name="HapChat"
          discription="Hap chat is chating application like WhatsApp, Telegram , Viber etc."
          link="/hapchat"
        />
      </div>
    </div>
  );
}
