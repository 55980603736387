import React from 'react';
import '../styles/about.css';
import Img from '../resources/aboutImg.jpg';

const AboutImg = () => {
  return (
    <img className='AboutImg mx-auto img-thumbnail bg-transparent border-0 ' src={Img} alt="sahan madusha"  />
  )
}

export default AboutImg
