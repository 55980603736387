import React from "react";
import logo from "../resources/logo.png";
import { FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp, IoLogoGithub } from "react-icons/io";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function Footer() {
  return (
    <div className="col-12 mt-5 p-lg-5 p-4 blue-service">
      <div className="row g-5">
        <div className="col-12 col-lg-4 offset-lg-1 offset-0">
          <div className="row g-5">
            <div className="col-8">
              <img src={logo} alt="logo" />
            </div>
            <div
              className="col-12 homePara text-white"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay=""
              data-aos-once="true"
            >
              From design to development, we're dedicated to providing our
              clients with exceptional service and results. Let's work together
              to bring your digital vision to life.
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-2  offset-0">
          <div className="row g-3">
            <div className="col-12 text-white fw-bold fs-4">Contact Us</div>
            <div
              className="col-12 homePara text-white"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay=""
              data-aos-once="true"
            >
              No:290/3, <br />
              Rajawella 02, Digana, <br />
              Sri Lanka, <br />
            </div>

            <div
              className="col-12 homePara text-white"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="100"
              data-aos-once="true"
            >
              taprodev@gmail.com
            </div>

            <div
              className="col-12 homePara text-white"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="200"
              data-aos-once="true"
            >
              (+94) 75 733 3502 <br />
              (+94) 77 161 7400
            </div>

            <div className="col-12 homePara fs-3 ">
              <div className="row gap-0 gap-lg-2">
                <div className="col-2 col-lg-2">
                  <Link className="social" to={"https://web.facebook.com/profile.php?id=100092424012955"}>
                    <FaFacebook />
                  </Link>
                </div>
                <div className="col-2 col-lg-2">
                  <Link className="social" to={"https://api.whatsapp.com/send?phone=94783814075&text="}>
                    <IoLogoWhatsapp />
                  </Link>
                </div>
                <div className="col-2 col-lg-2">
                  <Link className="social" to={"https://github.com/taproDev"}>
                    <IoLogoGithub />
                  </Link>
                </div>
                <div className="col-2 col-lg-2">
                  <Link className="social" to={"https://www.youtube.com/@taproDev"}>
                    <FaYoutube />
                  </Link>
                </div>
                <div className="col-2 col-lg-2">
                  <Link className="social" to={"https://www.linkedin.com/in/taprodev-solutions-643b5a275/"}>
                    <FaLinkedin />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 offset-lg-1  offset-0">
          <div className="row g-3">
            <div className="col-12 text-white fw-bold fs-4">Informations</div>
            <div
              className="col-12 homePara text-white "
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <Link className="footerLink">About Us</Link>
            </div>

            <div className="col-12 homePara text-white">
              <Link to={"/services"}
                className="footerLink"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-delay="50"
                data-aos-once="true"
              >
                Services
              </Link>
            </div>

            <div className="col-12 homePara text-white">
              <Link
                className="footerLink"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-delay="100"
                data-aos-once="true"
              >
                Testimonials
              </Link>
            </div>
            <div
              className="col-12 homePara text-white"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="150"
              data-aos-once="true"
            >
              <Link className="footerLink">Terms & Conditions</Link>
            </div>

            <div
              className="col-12"
            >
              <span className="text-white fw-bold">Newsletter</span>
            </div>

            <div className="col-12">
              <InputGroup className="mb-3"
              >
                <Form.Control
                  placeholder="Email"
                  className="bg-transparent text-white"
                />
                <Button variant="light">SUBSCRIBE</Button>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
