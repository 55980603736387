import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";

export default function FeedbackItem(props) {
  const starIcons = [];
  for (let i = 0; i < props.stars; i++) {
    starIcons.push(
      <StarIcon className="inline-block h-6 w-6 text-warning" key={i} />
    );
  }

  return (
    <div
      className="col-12 col-lg-5 whiteWork-bg p-4 mt-4 mt-lg-0 "
      
    >
      <div className="row g-2">
        <div className="col-12 text-1xl lg:text-2xl fw-bold">{props.title}</div>
        <div className="col-12">{starIcons}</div>
        <div className="Home-main-para text-black-50 mt-4 col-12">
          {props.feedback}
        </div>
        <div className="col-12 mt-4">
          <div className="row">
            <div className="col-5 col-lg-3 ">
              <img
                src={props.img}
                className="img-thumbnail bg-transparent border-0 "
                alt="img"
              />
            </div>
            <div className="col-7 col-lg-9 ">
              <span className="fw-bold fs-5">{props.name}</span>
              <br />
              <span className="text-sm text-aqua">{props.special}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
