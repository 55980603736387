import React from "react";
import { Link } from "react-router-dom";


export default function DarkService(props) {
  return (
    <div className="col-12 col-lg-6 p-3 p-lg-5 dark-service">
      <div className="row g-3">
        <div className="col-8 col-lg-10 text-white fw-bold text-2xl lg:text-4xl">
          {props.title}
        </div>
        <div className="col-4 col-lg-2">{props.children}</div>
        <div className="col-12 homePara text-sm lg:text-base">{props.discription}</div>
        <div className="col-12">
          <Link to={props.link}>
            <button className="round-btn mt-2 ">Discover</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
