import React, { useEffect } from "react";
import Nav from "../component/Nav";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import WebProjects from "../component/WebProjects";
import Footer from "../component/Footer";
import Contact from "../component/Contact";
import UiProjects from "../component/UiProjects";
import DesignProject from "../component/DesignProject";
import MobileProject from "../component/MobileProject";

export default function Works() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="container-fluid main-bg">
      <div className="row">
        
        <div className="col-12 works-bg">
          <div className="row ">
            
            <div className="col-12  text-center dark-bg">
            <Nav 
            active = "Our Works"/>
              <div
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-white text-center fw-bold text-5xl lg:text-7xl "
              >
                Our Works
              </div>
              <p className="Home-main-para mt-3 col-10 offset-1 col-lg-6 offset-lg-3 ">
                we offer a range of services that cater to all your digital
                needs. From web development to mobile application development,
                we've got you covered.
              </p>
              <Link to={"/contact"}>
                <button className="round-btn mt-3 mb-4 mt-lg-5 ">
                  Contact us
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 bg-info ">
          <div className="row">
            <a href="#WEB" className="col-12 col-lg-3 p-3 text-center fw-bold text-black text-decoration-none  workNav">
              <span><i class="bi bi-laptop"></i> &nbsp;WEB PROJECTS </span>
            </a>
            <a href="#MOBILE" className="col-12 col-lg-3 p-3 text-center fw-bold text-white darkWork text-decoration-none  ">
              <span><i class="bi bi-phone"></i> &nbsp;MOBILE APPLICATIONS </span>
            </a>
            <a href="#UI" className="col-12 col-lg-3 p-3 text-center fw-bold text-black text-decoration-none  workNav">
              <span><i class="bi bi-puzzle"></i> &nbsp;UI UX DESIGNS  </span>
            </a>
            <a href="#GRAPHICS" className="col-12 col-lg-3 p-3 text-center fw-bold  text-white darkWork text-decoration-none">
              <span><i class="bi bi-card-image"></i> &nbsp;GRAPHICS DESIGNS  </span>
            </a>
          </div>
        </div>
        <WebProjects />
        <MobileProject/>
        <UiProjects/>
        <DesignProject />
      
        <Contact/>

        <Footer/>
      </div>
    </div>
  );
}
