import React, { useEffect } from "react";
import Nav from "../component/Nav";
import { Link } from "react-router-dom";
import About from "./about";
import HomeTaskBar from "../component/HomeTaskBar";
import HomeOurWorks from "../component/HomeOurWorks";
import Testimonials from "../component/Testimonials";
import JoinUs from "../component/joinUs";
import Services from "../component/services";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../component/Footer";
import Technologies from "../component/technologies";
import { Helmet } from "react-helmet";

export default function Home() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="container-fluid main-bg">
      <div className="row">
        <Helmet>
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
          <meta
            name="description"
            content="Taprodev is a web development company based in Kandy, Sri Lanka. We specialize in mobile application development, UI/UX designing, and graphics designing. Contact us to bring your digital ideas to life!"
          />
          <meta
            name="keywords"
            content="
            taprodev,
            taprodev solutions,
            taprodev services,
            contact taprodev,
            web development,
            mobile application development,
            mobile application,
            graphics designing,
            logo design,
            web development company in sri lanka,
            sri lanka,
            kandy,
            graphic,
            a logo,
            app development,
            mobile app development,
            business logo,
            brand logo,
            business logo design,
            web dev"
          />
          <meta name="author" content="Taprodev" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Taprodev" />
          <meta
            property="og:description"
            content="Taprodev is a web development company based in Kandy, Sri Lanka. We specialize in mobile application development, UI/UX designing, and graphics designing. Contact us to bring your digital ideas to life!"
          />
          <meta
            property="og:image"
            content="https://taprodev.com/favicon.ico"
          />
          <meta property="og:url" content="https://taprodev.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Taprodev" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Taprodev" />
          <meta
            name="twitter:description"
            content="Taprodev is a web development company based in Kandy, Sri Lanka. We specialize in mobile application development, UI/UX designing, and graphics designing. Contact us to bring your digital ideas to life!"
          />
          <meta
            name="twitter:image"
            content="https://taprodev.com/favicon.ico"
          />
          <title>Taprodev Solutions</title>
        </Helmet>

        <div className=" col-12 head">
          <div className="row">
            <div className="head-dark-bg col-12 ">
              <Nav active="Home" />
              <div className="row">
                <div className="col-10 offset-1 ">
                  <div className="row">
                    <div className="col-12 col-lg-7 mt-5">
                      <div
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-once="true"
                        className="text-white fw-bold text-5xl lg:text-7xl "
                      >
                        Design your digital Destiny with us!
                      </div>
                      <p
                        className="Home-main-para mt-3 text-sm lg:text-base"
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-delay="300"
                        data-aos-once="true"
                      >
                        From design to development, we're dedicated to providing
                        our clients with exceptional service and results. Let's
                        work together to bring your digital vision to life.
                      </p>
                      <Link to={"/contact"}>
                        <button
                          className="round-btn mt-3 mb-4 mt-lg-5 "
                          data-aos="fade-right"
                          data-aos-duration="500"
                          data-aos-delay="500"
                          data-aos-once="true"
                        >
                          Contact us
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeTaskBar />

        <div className="col-12">
          <div className="row">
            <About />
          </div>

          <Services />

          <HomeOurWorks />
          <Technologies />
          <Testimonials />
          <JoinUs />
        </div>

        <Footer />
      </div>
    </div>
  );
}
