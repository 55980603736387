import React from "react";
import DetailTable from "../component/DetailTable";
import Nav from "../component/Nav";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Pdf from "../Uifiles/edubook.pdf";
import Doc from "../documents/edubook.pdf";
import SignleProjectDiscription from "../component/SignleProjectDiscription"

export default function Edubook() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />

        <div className="col-10 offset-1">
          <div className="row">
            
            <SignleProjectDiscription
            type = "LEARNING MANAGEMENT SYSTEM"
            name = "EDU BOOK"
            dis1= "This is a system can use in goverment school and unvercity s. This System is also known as a Student Information System (SIS). This systems work to coordinate scheduling and communications between faculty regarding students. This system exists to simplify information tracking for both parents and administrative staff."
            dis2= ""
            youtube = "https://www.youtube.com/embed/n4H6oaTaoSI"
            />

            

      
            <DetailTable
              link=""
              doc={Doc}
              ui={Pdf}
              special="Front End & BackEnd"
            />
          </div>
        </div>

        <Contact />
        <Footer />
      </div>
    </div>
  );
}
