import React from 'react'




export default function WorksImgTwo(props) {
  
  return (
    <div className='col-12 col-lg-6 '
    data-aos="fade-up"
    data-aos-duration="500"
    data-aos-delay="300"
    data-aos-once = "true"
   >
    <div className='row'>
      <div className='col-12' style={{backgroundImage: `url(${props.img})`,backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '60vh',}}>
      </div>
    </div>
    </div>
  )
}
